<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="消防控制室名称">
          <el-input v-model="searchForm.roomName" placeholder="请输入消防控制室名称" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="recordList" stripe style="width: 100%" height="100%">
        <el-table-column prop="roomName" label="消防室控制室名称" show-overflow-tooltip width="150" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="default" @click="open('result',scope.row)">{{scope.row.roomName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="personName" label="值班管理人员" show-overflow-tooltip width="100" align="center">
        </el-table-column>
        <el-table-column prop="dutyTime" label="值班日期" show-overflow-tooltip width="100" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.dutyTime|timeFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dutyType" label="值班班次" show-overflow-tooltip width="100" align="center">
          <template slot-scope="scope">
            <el-tag type="primary" size="normal" v-show="scope.row.dutyType===1">早班</el-tag>
            <el-tag type="primary" size="normal" v-show="scope.row.dutyType===2">中班</el-tag>
            <el-tag type="primary" size="normal" v-show="scope.row.dutyType===3">晚班</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="消防控制室值班情况" show-overflow-tooltip width="100" align="center">
          <el-table-column label="火灾报警控制器运行情况" show-overflow-tooltip width="100" align="center">
            <el-table-column prop="deviceRun" label="设备运行情况" show-overflow-tooltip width="100" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.deviceRun===1">异常</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.deviceRun===0">正常</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="checkTrouble" label="故障及处理情况" show-overflow-tooltip width="100" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column label="控制室内其他消防系统运行情况" show-overflow-tooltip width="100" align="center">
            <el-table-column prop="deviceName" label="设备名称" show-overflow-tooltip width="100" align="center">
            </el-table-column>
            <el-table-column prop="otherControlStatus" label="控制状态" show-overflow-tooltip width="100" align="center">
              <template slot-scope="scope">
                <el-tag type="primary" size="normal" v-show="scope.row.deviceRun===1">电动</el-tag>
                <el-tag type="primary" size="normal" v-show="scope.row.deviceRun===0">手动</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="otherDeviceRun" label="设备运行情况" show-overflow-tooltip width="100" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.deviceRun===1">异常</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.deviceRun===0">正常</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="otherTrouble" label="故障及处理情况" show-overflow-tooltip width="100" align="center">
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column label="火灾报警器日常检查情况" show-overflow-tooltip width="100" align="center">
          <el-table-column prop="checkContent" label="检查内容" show-overflow-tooltip width="100" align="center">
          </el-table-column>
          <el-table-column prop="checkTime" label="检查时间" show-overflow-tooltip width="100" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.checkTime|timeFormat}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="checkPerson" label="检查人" show-overflow-tooltip width="100" align="center">
          </el-table-column>
          <el-table-column prop="trouble" label="故障及处理情况" show-overflow-tooltip width="100" align="center">
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 详情 -->
    <el-drawer title="核查时间" :visible.sync="recordShow" direction="rtl" :before-close="handleClose" size="33%">
      <el-button type="success" size="small" icon="el-icon-printer">打印</el-button>
      <el-form label-position="left" inline class="drawer-form" label-width="150px">
        <el-form-item label="消防室控制室名称">
          <span>{{record.roomName}}</span>
        </el-form-item>
        <el-form-item label="值班管理人员">
          <span>{{record.personName}}</span>
        </el-form-item>
        <el-form-item label="值班日期">
          <span>{{record.dutyTime|timeFormat}}</span>
        </el-form-item>
        <el-form-item label="值班班次">
          <el-tag type="primary" size="normal" v-show="record.dutyType===1">早班</el-tag>
          <el-tag type="primary" size="normal" v-show="record.dutyType===2">中班</el-tag>
          <el-tag type="primary" size="normal" v-show="record.dutyType===3">晚班</el-tag>
        </el-form-item>
        <div class="title">
          一、火灾报警控制器运行情况
        </div>
        <el-form-item label="设备运行情况">
          <el-tag type="success" size="normal" v-show="record.deviceRun===1">异常</el-tag>
          <el-tag type="danger" size="normal" v-show="record.deviceRun===0">正常</el-tag>
        </el-form-item>
        <el-form-item label="故障及处理情况">
          <el-input type="textarea" :rows="3" v-model="record.checkTrouble" readonly></el-input>
        </el-form-item>
        <div class="title">
          二、控制室内其他消防系统运行情况
        </div>
        <el-form-item label="设备名称">
          <span>{{record.deviceName}}</span>
        </el-form-item>
        <el-form-item label="控制状态">
          <el-tag type="primary" size="normal" v-show="record.otherControlStatus===1">电动</el-tag>
          <el-tag type="primary" size="normal" v-show="record.otherControlStatus===0">手动</el-tag>
        </el-form-item>
        <el-form-item label="设备运行情况">
          <el-tag type="success" size="normal" v-show="record.otherDeviceRun===1">异常</el-tag>
          <el-tag type="danger" size="normal" v-show="record.otherDeviceRun===0">正常</el-tag>
        </el-form-item>
        <el-form-item label="故障及处理情况">
          <el-input type="textarea" :rows="3" v-model="record.otherTrouble" readonly></el-input>
        </el-form-item>
        <div class="title">
          三、火灾报警器日常检查情况
        </div>
        <el-form-item label="检查内容">
          <span>{{record.checkContent}}</span>
        </el-form-item>
        <el-form-item label="检查人">
          <span>{{record.checkPerson}}</span>
        </el-form-item>
        <el-form-item label="检查时间">
          <span>{{record.checkTime|timeFormat}}</span>
        </el-form-item>
        <el-form-item label="故障及处理情况">
          <el-input type="textarea" :rows="3" v-model="record.trouble" readonly></el-input>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentPage: 1,
      total: 0,
      recordList: [],
      typeList: [],
      searchForm: {},
      recordShow: false,
      record: {}
    }
  },
  created () {
    this.initTable()
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
    },
    async initTable () {
      const data = {}
      if (this.searchForm.roomName) {
        data.roomName = this.searchForm.roomName
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      const { data: result } = await this.$axios.get('/fireControlDutyRecord/list', { params: data })
      if (result.code === 200) {
        this.recordList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    // 查找行政区域
    async getTypeList () {
      const { data: result } = await this.$axios.get('/equipment-type/list')
      if (result.code === 200) {
        this.typeList = result.data
      } else {
        this.$message.error(result.msg)
      }
    },
    open (flag, row) {
      switch (flag) {
        case 'result':
          this.record = row
          this.recordShow = true
          break
      }
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
::v-deep .el-drawer__body {
  overflow-y: scroll;
  .drawer-form {
    font-size: 0;
    padding: 10px 40px;
    .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 100%;
      label {
        color: #99a9bf !important;
        float: left;
      }
      .el-form-item__content {
        float: right;
        width: calc(100% - 150px);
      }
    }
    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      box-sizing: border-box;
      background: linear-gradient(
        90deg,
        #23527e 0%,
        rgba(57, 184, 230, 0) 100%
      );
      font-size: 16px;
      text-align: left;
      margin-top: 10px;
    }
  }
}
::v-deep  :focus {
  outline: 0;
}
.el-table th.gutter {
  display: table-cell !important;
}

.el-table colgroup.gutter {
  display: table-cell !important;
}
</style>
